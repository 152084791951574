@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  scroll-behavior: smooth;
  font-family: "Montserrat", sans-serif;
}

header {
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  transform: translateZ(0);
}

.button {
  height: 3.5rem;
  padding: 0 2rem;
  border: 0.1rem solid #000;
  border-radius: 3.12rem;
  box-shadow: 0 0.4rem 0 0 #000;
  background-color: #f5a841;
  color: #000;
  font-family: "Epilogue", sans-serif;
  font-size: 1.2rem;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    transform: translateY(0.3rem);
    box-shadow: 0 0.5rem 0 0 #000;
  }
}

.modal-overlay {
  position: fixed; 
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7); 
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; 
}

.modal-content {
  padding: 40px;
  width: 90%;
  max-width: 600px; 
  max-height: 700px;
  position: relative; 
}

.close-button {
  position: absolute;
  top: 12px;
  right: 16px;
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #f5a841;
}
